<template>
  <v-dialog v-model="active" fullscreen>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>Reserve{{ hasJury ? ' Another' : ''}} Jury Slot</v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Reserve Jury Slot</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="active = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p class="mt-4">Please select your instructor and accompanist (if you need one) to show the available time slots. Then choose your instrument and select your desired time slot and the Save button will appear in the bottom-right corner (along with a button to clear the current slot selection).</p>
        <v-row style="margin-top:1em">
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="instructorFilter" :items="instructors" label="Instructor" outlined hide-details></v-autocomplete>
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="accompanistFilter" :items="accompanists" label="Accompanist" outlined hide-details></v-autocomplete>
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-autocomplete v-model="instrument" :items="instruments" label="Instrument" outlined hide-details></v-autocomplete>
          </v-col>
          <v-col :cols="12" md="6" lg="4" xl="3">
            <v-checkbox v-model="sophomorePlatform" label="This is my Sophomore Platform" hide-details>
              <template v-slot:append>
                <v-btn color="info" icon @click="sophomorePlatformHelp = !sophomorePlatformHelp" style="margin-top:-8px">
                  <v-icon>fas fa-question-circle</v-icon>
                </v-btn>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-alert v-if="sophomorePlatformHelp" type="info" dismissible @close="sophomorePlatformHelp = false">Your sophomore platform will be a 20-minute slot instead of the usual 10-minute slot. All of the slots that do not have an available slot before or after it have been removed. Click on the slot for the first 10-minutes and the slot after will automatically be added. If you click the last slot then the one prior will be selected.</v-alert>
      </v-card-text>
      <v-card-text>
        <jury-calendar :term="term" :instructor="instructorFilter" :accompanist="accompanistFilter" :long-slot="sophomorePlatform" :override-event="selectedSlot" @clickEvent="clickEvent"></jury-calendar>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
      </v-card-actions>
      <v-tooltip v-if="selectedSlot.name !== ''" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="instrument === ''" fab bottom right fixed color="success" @click="save">
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </template>
        <span>Reserve the selected time slot</span>
      </v-tooltip>
      <v-tooltip v-if="selectedSlot.name !== ''" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" fab bottom right fixed color="error" style="transform: translateX(-64px)" @click="clear">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </template>
        <span>Clear Slot Selection</span>
      </v-tooltip>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
export default {
  components: {
    JuryCalendar: () => import('./juryCalendar')
  },
  props: {
    term: {
      type: String,
      required: true
    },
    hasJury: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = ref(false)
    const instructors = ref([])
    const instructorFilter = ref('')
    const accompanists = ref([])
    const accompanistFilter = ref('No Accompanist')
    const instruments = ref(['Piano', 'Organ', 'Voice', 'Violin', 'Viola', 'Cello', 'String Bass', 'Guitar', 'Jazz Piano', 'Trumpet', 'French Horn', 'Trombone', 'Euphonium', 'Tuba', 'Flute', 'Clarinet', 'Oboe', 'English Horn', 'Bass Clarinet', 'Bassoon', 'Percussion'])
    const instrument = ref('')
    const sophomorePlatform = ref(false)
    const sophomorePlatformHelp = ref(false)

    const service = root.$feathers.service('student/music/jury-slot')
    onMounted(() => {
      // Load a list of the unique instructors and accompanists
      service.find({ query: { $distinct: 'avail.instr', term: props.term } }).then(({ data }) => {
        instructors.value = []
        const hash = {}
        for (const { _id: names } of data) {
          for (const name of names) {
            if (!hash[name]) {
              hash[name] = true
              instructors.value.push(name)
            }
          }
        }
      })
      service.find({ query: { $distinct: 'avail.accomp', term: props.term } }).then(({ data }) => {
        accompanists.value = ['No Accompanist']
        const hash = {}
        for (const { _id: names } of data) {
          for (const name of names) {
            if (!hash[name]) {
              hash[name] = true
              accompanists.value.push(name)
            }
          }
        }
      })
    })

    const selectedSlot = ref({
      name: ''
    })

    function clickEvent (event) {
      selectedSlot.value = { ...event, color: 'orange', name: 'Pending Slot' }
    }
    async function save () {
      const { id, start, end } = selectedSlot.value
      const patch = {
        student: user.value.directoryId,
        instructor: instructorFilter.value,
        instrument: instrument.value,
        accomp: accompanistFilter.value
      }
      if (sophomorePlatform.value) {
        // Need to find the overlapping slot and remove it
        const query = { _id: { $ne: id }, start: { $gt: start, $lt: end } }
        const { data } = await service.find({ query })
        if (data.length === 1) {
          try {
            console.log(data[0])
            await service.patch(data[0]._id, { active: false })
          } catch (e) {
            console.log(e)
            root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error removing extra slot: ' + e, timeout: 8000 })
            return
          }
        } else if (data.length > 1) {
          console.log(data)
          return
        }
        // Modify the patch for the current slot to extend the end time
        patch.end = end
      }
      try {
        const event = await service.patch(id, patch)
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', text: 'Slot reserved successfully', timeout: 6000 })
        emit('reserved', { event })
        active.value = false
        clear()
        instructorFilter.value = ''
        accompanistFilter.value = ''
        instrument.value = ''
      } catch (e) {
        console.log(e)
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', text: 'Error reserving slot: ' + e, timeout: 8000 })
      }
    }
    function clear () {
      selectedSlot.value = { name: '' }
    }

    return {
      user,
      active,
      instructors,
      instructorFilter,
      accompanists,
      accompanistFilter,
      instruments,
      instrument,
      sophomorePlatform,
      sophomorePlatformHelp,
      selectedSlot,
      clickEvent,
      save,
      clear
    }
  }
}
</script>
